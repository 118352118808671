import { Doughnut } from "react-chartjs-2";
import { useIsMobile } from "../../utils/useIsMobile";
import { useMemo } from "react";
import { getTaxPaid } from "../../pages/tax_plan/functions";
import numeral from "numeral";
import { cn } from "../../utils/useFunctions";

const HalfChartMain = ({
  value,
  isSum,
  maxvalue,
  beforevalue,
  aftervalue,
  form,
  text,
  fontSize = "text-[32px]",
  chartWidth = "w-[274px]",
  margin = "",
  className = "-mt-28",
  width,
  height,
  isPdf = false,
  mtText = "-mt-32",
}) => {
  const isMobile = useIsMobile();

  const data = {
    // labels: ["Red", "Blue", "Yellow"],
    datasets: [
      {
        label: "My First Dataset",
        data: [beforevalue, maxvalue],

        backgroundColor: ["rgba(249, 0, 0, 1)", "rgba(234, 234, 234, 1)"],
        hoverOffset: 4,
        cutout: "70%",
        circumference: 180,
        rotation: 270,
        borderRadius: 0,
      },
      {
        label: "My First Dataset",
        data: [aftervalue, maxvalue],
        backgroundColor: ["rgba(22, 170, 55, 1)", "rgba(224, 223, 223, 1)"],
        hoverOffset: 4,
        cutout: "70%",
        circumference: 180,
        rotation: 270,
        borderRadius: 0,
      },
    ],
  };
  const config = {
    type: "doughnut",
    data: data,
  };

  return (
    <>
      <div
        className={cn(`  relative ${className}`, {
          "-mt-20 mb-16 md:mb-20": !isPdf,
        })}
      >
        <div className={` ${chartWidth} mx-auto `}>
          <Doughnut width={width} height={height} data={data} config={config} />
        </div>

        {text ? (
          <>
            {isSum ? (
              <div
                className={cn(
                  `text-center ${fontSize} font-IBMPlexSansThai font-medium text-mainblue`,
                  mtText
                )}
              >
                ภาษีลดลง
              </div>
            ) : (
              <div
                className={cn(
                  " text-sm font-IBMPlexSansThai font-medium text-center -mt-32",
                  mtText
                )}
              >
                ภาษีลดลง
                <div className="text-[32px] font-semibold text-mainorange text-center leading-normal">
                  {value} %
                </div>
              </div>
            )}
          </>
        ) : (
          <div
            className={cn(
              `${margin}  text-center ${fontSize} font-IBMPlexSansThai font-medium text-mainblue -mt-32`,
              mtText
            )}
          >
            ภาษีลดลง
          </div>
        )}
      </div>
    </>
  );
};

export default HalfChartMain;
