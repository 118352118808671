import { useNavigate } from "react-router-dom";
import { HeaderTitle } from "../../../components/header/Header";
import Container from "../../../components/layout/Container";
import NavigateGoBack, {
  NavigatePath,
} from "../../../functions/Navigate.Goback";
import {
  STYLE_HEAD_TITLE,
  STYLE_MARGIN_TOP_PARENT,
  STYLE_PADDING_BALANCE_DRAWER,
} from "../../../utils/useStyle";
import { MTButton } from "../../../components/button/MTButton";
import {
  PanelDeduct,
  PanelExceptions,
  PanelNetIncome,
  PanelSumIncomeTax,
  PanelSumTaxDetails,
} from "../styles/Panel_Main";
import { useTaxStore } from "../../../_store/taxplanStore";
import numeral from "numeral";
import { VITaxTable } from "./VITaxTable";
import { useEffect, useMemo, useState } from "react";
import { first_reload } from "../functions/firstLoad";
import { useTaxPlanContext } from "../Tax_Plan.Main";
import TaxHeirachy, { DeductionSum, ExceptionLists, ExceptionSum, TaxHeirachy2 } from "../../../functions/taxFucntion/taxHeirachy";

// รายได้
export const ViewDetailsTaxIncome = ({ setTab }) => {
  const taxData = useTaxStore((state) => state.taxData);

  const isGoback = () => {
    setTab(3);
  };

  useEffect(() => {
    let ignore = false;
    first_reload(ignore);
    return () => {
      ignore = true;
    };
  }, []);

  return (
    <div id="first_reload">
      <div className={`${STYLE_MARGIN_TOP_PARENT}  `}>
        <HeaderTitle
          title="รายละเอียดรายได้"
          isGoback
          onClickGoBack={isGoback}
        />
        <PanelSumTaxDetails
          cols={2}
          title={"รายได้จากเงินเดือน"}
          value={numeral(taxData?.tax_income)?.format("0,0")}
          total={numeral(taxData?.tax_income)?.format("0,0")}
        />
      </div>
      <div className=" flex justify-center items-center mt-5 px-6 mb-10">
        <MTButton text="ย้อนกลับ" width="w-full" onClick={isGoback} />
      </div>
    </div>
  );
};

// ยกเว้น
export const ViewDetailsTaxExemption = ({ setTab, form }) => {
  const taxData = useTaxStore((state) => state.taxData);

  // รายการก่อนวางแผน
  const { deduction, setDeduction } = useTaxPlanContext();

  // tax_income
  const ProvidentFundCalc = (value) => {
    let sum = 0;
    if (taxData?.tax_income * 0.15 >= 490000 && value >= 490000) {
      return (sum = 490000);
    }
    if (value >= taxData?.tax_income * 0.15) {
      return (sum = taxData?.tax_income * 0.15 - 10000);
    }

    if (value < taxData?.tax_income * 0.15) {
      return (sum = value - 10000);
    }
  };

  const isGoback = () => {
    setTab(3);
  };

  useEffect(() => {
    let ignore = false;
    try {
      first_reload(ignore);
    } catch (error) {
      console.log(error);
    }
    return () => {
      ignore = true;
    };
  }, []);


  return (
    <div id="first_reload">
      <div className={`${STYLE_MARGIN_TOP_PARENT}  `}>
        <HeaderTitle
          title="รายละเอียดเงินได้ที่ได้รับการยกเว้น"
          isGoback
          onClickGoBack={isGoback}
        />
        <PanelExceptions
          head_1={<div>จำนวนเงินที่ระบุ</div>}
          head_2={<div>จำนวนเงินที่ได้สิทธิ</div>}
          data={TaxHeirachy()}
          ExceptionLists={ExceptionLists}
          displayPart = {["Exception"]}
          total={numeral(DeductionSum()).format("0,0")}
          totalException={numeral(ExceptionSum()).format("0,0")}
          title={"เงินเดือนค่าจ้างที่ได้รับจากบริษัทฯ บุคคลทั่วไป ฯลฯ"}
          value={"50"}
          value2={"100,000"}
        />
      </div>
      <div className=" flex justify-center items-center mt-5 px-6 mb-10">
        <MTButton text="ย้อนกลับ" width="w-full" onClick={isGoback} />
      </div>
    </div>
  );
};

// ค่าใช้จ่าย
export const ViewDetailsTaxExpense = ({ setTab, section = "fourth" }) => {
  const isGoback = () => {
    if (section === "fourth") {
      setTab(3);
      return;
    }

    if (section === "second") {
      setTab(1);
      return;
    }
  };

  useEffect(() => {
    let ignore = false;
    first_reload(ignore);
    return () => {
      ignore = true;
    };
  }, []);

  return (
    <div id="first_reload">
      <div className={`${STYLE_MARGIN_TOP_PARENT}  `}>
        <HeaderTitle
          title="รายได้การหักค่าใช้จ่าย"
          isGoback
          onClickGoBack={isGoback}
        />
        <PanelSumTaxDetails
          head_1={
            <div>
              อัตราค่าใช้จ่าย
              <div className=" text-center">(ร้อยละ)</div>
            </div>
          }
          head_2={
            <div>
              {" "}
              จำนวนค่าใช้จ่าย
              <div className=" text-center">(บาท)</div>
            </div>
          }
          title={"เงินเดือนค่าจ้างที่ได้รับจากบริษัทฯ บุคคลทั่วไป ฯลฯ"}
          value={"50"}
          value2={"100,000"}
          total={"100,000"}
        />
      </div>
      <div className=" flex justify-center items-center mt-5 px-6 mb-10">
        <MTButton text="ย้อนกลับ" width="w-full" onClick={isGoback} />
      </div>
    </div>
  );
};

// ค่าลดหย่อน
export const ViewDetailsTaxDeduct = ({ setTab, section = "fourth", form={} }) => {
  const taxData = useTaxStore((state) => state.taxData);

  // รายการก่อนวางแผน
  const { deduction, setDeduction } = useTaxPlanContext();

  // รายการที่วางแผน
  const deductionAF = useMemo(() => {
    let result = [];
    if (taxData?.tax_plan_insurance_life) {
      result.push({
        key: "tax_plan_insurance_life",
        title: "ประกันชีวิต",
        amount: taxData.tax_plan_insurance_life,
      });
    }
    if (taxData?.tax_plan_insurance_health) {
      result.push({
        key: "tax_plan_insurance_health",
        title: "ประกันสุขภาพ",
        amount: taxData?.tax_plan_insurance_health,
      });
    }
    if (taxData?.tax_plan_insurance_annuity) {
      result.push({
        key: "tax_plan_insurance_annuity",
        title: "ประกันชีวิตแบบบำนาญ",
        amount: taxData?.tax_plan_insurance_annuity,
      });
    }
    if (taxData?.tax_fund_provident) {
      result.push({
        key: "tax_fund_provident",
        title: "เงินสะสมกองทุนสำรองเลี้ยงชีพ",
        amount: taxData?.tax_fund_provident,
      });
    }
    if (taxData?.tax_fund_nsf) {
      result.push({
        key: "tax_fund_nsf",
        title: "เงินสะสมกองทุนการออมแห่งชาติ (กอช)  ",
        amount: taxData?.tax_fund_nsf,
      });
    }
    if (taxData?.tax_fund_esg) {
      result.push({
        key: "tax_fund_esg",
        title: "ค่าซื้อหน่วยลงทุนกองทุนรวมไทยเพื่อความยั่งยืน (Thailand ESG)",
        amount: taxData?.tax_fund_esg,
      });
    }
    if (taxData?.tax_plan_rmf) {
      result.push({
        key: "tax_plan_rmf",
        title: "กองทุนรวมเพื่อการเลี้ยงชีพ (RMF)",
        amount: taxData?.tax_plan_rmf,
      });
    }
    if (taxData?.tax_plan_ssf) {
      result.push({
        key: "tax_plan_ssf",
        title: "กองทุนรวมเพื่อการออม (SSF)",
        amount: taxData?.tax_plan_ssf,
      });
    }
    if (taxData?.tax_plan_political_donate) {
      result.push({
        key: "tax_plan_political_donate",
        title: "เงินบริจาคแก่พรรคการเมือง",
        amount: taxData?.tax_plan_political_donate,
      });
    }
    return result;
  }, [
    taxData?.tax_plan_insurance_life,
    taxData?.tax_plan_insurance_health,
    taxData?.tax_plan_insurance_annuity,
    taxData?.tax_fund_provident,
    taxData?.tax_fund_nsf,
    taxData?.tax_plan_rmf,
    taxData?.tax_plan_ssf,
    taxData?.tax_plan_political_donate,
    taxData?.tax_fund_esg,
  ]);

  const isGoback = () => {
    if (section === "second") {
      setTab(1);
      return;
    }

    if (section === "fourth") {
      setTab(3);
      return;
    }
  };

  useEffect(() => {
    let ignore = false;
    try {
      first_reload(ignore);
    } catch (error) {
      console.log(error);
    }
    return () => {
      ignore = true;
    };
  }, []);


  return (
    <div id="first_reload">
      <div className={`${STYLE_MARGIN_TOP_PARENT}  `}>
        <HeaderTitle
          title="รายละเอียดค่าลดหย่อน"
          isGoback
          onClickGoBack={isGoback}
        />
        <PanelExceptions
          head_1={<div>จำนวนเงินที่ระบุ</div>}
          head_2={<div>จำนวนเงินที่ได้สิทธิ</div>}
          data={TaxHeirachy2(form)}
          ExceptionLists={ExceptionLists}
          displayPart = {["Deduction"]}
          form={form}
          total={numeral(DeductionSum(form)||0).format("0,0")}
          totalException={numeral(ExceptionSum(form)||0).format("0,0")}
          title={"เงินเดือนค่าจ้างที่ได้รับจากบริษัทฯ บุคคลทั่วไป ฯลฯ"}
          value={"50"}
          value2={"100,000"}
        />
      </div>
      <div className=" flex justify-center items-center mt-5 px-6 mb-10">
        <MTButton text="ย้อนกลับ" width="w-full" onClick={isGoback} />
      </div>
    </div>
  );
};

// ค่าลดหย่อนอันแรก
export const ViewDetailsTaxDeductFirst = ({
  setTab,
  section = "fourth",
  form,
}) => {

  const totalTaxDeduct = TaxHeirachy(form).filter((item) => {
    if (item?.title === "เงินสะสมกองทุนสำรองเลี้ยงชีพ" ) {
      return item;
    }
    if (!ExceptionLists.includes(item?.title)) {
      return item;
    }
  }).reduce((sum, item) => {
    if (item?.title === "เงินสะสมกองทุนสำรองเลี้ยงชีพ") {
      if (item?.value2 >= 10000) {
        return sum + 10000;
      }
      else{
      return sum + item?.value2;
      }
    }

    return sum + item?.value2;
  }, 0);

  const totalException = TaxHeirachy(form)
    .filter((item) => {
      if (item?.title === "เงินสะสมกองทุนสำรองเลี้ยงชีพ" ) {
        return item;
      }
      if (ExceptionLists.includes(item?.title)) {
        return item;
      }
    })
    .reduce((sum, item) => {
      if (item?.title === "เงินสะสมกองทุนสำรองเลี้ยงชีพ") {
        if (item?.value2 >= 10000) {
          return sum + Number(item?.value2 || 0) - 10000;
        }else{
          return sum + Number(item?.value2 || 0 );
        }
      }
      return sum + Number(item?.value2 || 0);
    }, 0);

  const isGoback = () => {
    if (section === "second") {
      setTab(1);
      return;
    }

    if (section === "fourth") {
      setTab(3);
      return;
    }
  };

  useEffect(() => {
    let ignore = false;
    try {
      first_reload(ignore);
    } catch (error) {
      console.log(error);
    }
    return () => {
      ignore = true;
    };
  }, []);

  // console.log("totalTaxDeduct",totalTaxDeduct)

  return (
    <div id="first_reload">
      <div className={`${STYLE_MARGIN_TOP_PARENT}  `}>
        <HeaderTitle
          title="รายละเอียดค่าลดหย่อน"
          isGoback
          onClickGoBack={isGoback}
        />
        <PanelExceptions
          head_1={<div>จำนวนเงินที่ระบุ</div>}
          head_2={<div>จำนวนเงินที่ได้สิทธิ</div>}
          data={TaxHeirachy(form)}
          form={form}
          title={"เงินเดือนค่าจ้างที่ได้รับจากบริษัทฯ บุคคลทั่วไป ฯลฯ"}
          total={numeral(totalTaxDeduct)?.format("0,0")}
          totalException={numeral(totalException)?.format("0,0")}
          ExceptionLists={ExceptionLists}
        />
      </div>
      <div className=" flex justify-center items-center mt-5 px-6 mb-10">
        <MTButton text="ย้อนกลับ" width="w-full" onClick={isGoback} />
      </div>
    </div>
  );
};

//ค่าลดหย่อนแรก

// เงินได้สุทธิ
export const ViewDetailsTaxNetIcome = ({ setTab, form={} }) => {
  const taxData = useTaxStore((state) => state.taxData);

  const isGoback = () => {
    setTab(3);
  };

  useEffect(() => {
    let ignore = false;
    first_reload(ignore);
    return () => {
      ignore = true;
    };
  }, []);

  return (
    <div id="first_reload">
      <div className={`${STYLE_MARGIN_TOP_PARENT}  `}>
        <HeaderTitle
          title="รายละเอียดเงินได้สุทธิ"
          isGoback
          onClickGoBack={isGoback}
        />
        <PanelNetIncome
          income={numeral(taxData.tax_income)?.format("0,0")}
          exception={numeral(ExceptionSum(form)||0)?.format("0,0")}
          expense={numeral(taxData?.deduction_expenses||0)?.format("0,0")}
          deduct={numeral(DeductionSum(form)||0)?.format("0,0")}
          total={numeral(taxData.tax_income-(ExceptionSum(form)||0)-(taxData?.deduction_expenses||0)-(DeductionSum(form)||0))?.format("0,0")}
        />
      </div>
      {/*---- ตารางขั้นบันได ----*/}
      <div className=" px-6 rounded-[20px] h-[600px]  mt-[14px]">
        <div className="overflow-hidden   ">
          <VITaxTable totalIncome={taxData.tax_income-(ExceptionSum(form)||0)-(taxData?.deduction_expenses||0)-(DeductionSum(form)||0)} />
        </div>
      </div>
      <div className=" flex justify-center items-center mt-[60px] px-6 mb-10">
        <MTButton text="ย้อนกลับ" width="w-full" onClick={isGoback} />
      </div>
    </div>
  );
};
