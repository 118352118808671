import { Progress } from "@material-tailwind/react";
import {
  STYLE_CARD_BG,
  STYLE_IMG_DRAWER,
  STYLE_INPUT_ERROR,
  STYLE_INPUT_INSET,
  STYLE_PADDING_BALANCE_DRAWER,
  STYLE_TITLE_DRAWER,
} from "../../../utils/useStyle";
import { CardMain } from "../../../components/card/CardMain";
import { ICON_CLOSE_NO_CIIRCLE, ICON_INFO } from "../../../utils/useIcons";
import numeral from "numeral";
import { NumericFormat } from "react-number-format";
import NumbericValidateRed from "../../../components/DebtBalance/numericRed";
import { useState } from "react";
import { PopupMain } from "../../../components/popup/PopupMain";
import { cn } from "../../../utils/useFunctions";
import { IncomeAfterCal } from "../../../functions/taxFucntion/taxHeirachy";

export const PanelTax = ({
  title,
  title2,
  subtitle,
  IsDisplayRemain = true,
  remain,
  value,
  valueDisplay,
  maxvalue,
  valueprogress,
  onChange,
  img,
  error,
  errortext = true,
  full,
  etc,
  textetc,
  textetc2,
  errorstyle1,
  errorstyle2,
  disabled,
  maxLength,
  panel2,
  paidPerYear = "เบี้ยประกันที่จ่ายตลอดทั้งปี",
  paidPerYearBorder = true,
  paidPerYearMargin = "mt-6",
  infoPopUpHeader = "",
  infoPopUpText = "",
  reverse = false,
}) => {
  const [openInfoPopUp, setOpenInfoPopUp] = useState(false);

  return (
    <div>
      <div className="mt-16 ">
        <div className="flex w-full justify-center ">
          <div className={`${STYLE_TITLE_DRAWER} `}>
            {title}

            {title2 && <div>{title2}</div>}
          </div>
          <div className="flex items-end pb-[10px] ">
            <ICON_INFO
              onClick={() => {
                setOpenInfoPopUp(!openInfoPopUp);
              }}
              className={` w-4 h-4 text-maingray mt-1`}
            />
          </div>
        </div>

        {subtitle && (
          <div className=" text-md text-center text-mainblue font-semibold mt-1">
            {subtitle}
          </div>
        )}
        <div
          className={cn(" pt-6 flex flex-col", {
            " flex-col-reverse ": reverse,
          })}
        >
          {IsDisplayRemain && (
            <div
              className={cn(`${STYLE_CARD_BG} p-[10px] mb-5 flex flex-col `, {
                " mt-5 ": reverse,
              })}
            >
              <div
                className={cn(" flex justify-between", {
                  " justify-between ": !reverse,
                })}
              >
                {!error && (
                  <div className="  md:text-lg text-sm font-medium pl-[14px] text-black flex flex-col ">
                    ใช้สิทธิไปแล้ว
                  </div>
                )}
                {error && reverse && (
                  <div className="  md:text-lg text-sm font-medium pl-[14px] text-black flex flex-col ">
                    <div>ใช้เต็มสิทธิ</div>
                    <div className="text-red-500">{`${numeral(maxvalue).format(
                      "0,0"
                    )} บาท`}</div>
                  </div>
                )}
                {full ? (
                  ""
                ) : (
                  <div className=" md:text-lg text-sm font-normal text-maingray">
                    ( คงเหลือ {error ? 0 : numeral(remain).format("0,0")} บาท )
                  </div>
                )}
              </div>
              <div className="flex justify-between items-end w-full ">
                <div className=" md:w-2/3 ">
                  <div className=" font-semibold text-black md:text-2xl  text-xl w-full mb-1 flex gap-1">
                    {value ? (
                      error ? (
                        <div className="text-red-500">
                          {numeral(value).format("0,0")}
                        </div>
                      ) : (
                        numeral(Number(value)).format("0,0")
                      )
                    ) : (
                      0
                    )}
                    / {numeral(maxvalue).format("0,0")} ต่อปี
                  </div>
                  <Progress
                    value={valueprogress}
                    size=" md:lg sm"
                    className=" bg-white w-full"
                    color="blue"
                  />
                </div>
                <img src={img} className={STYLE_IMG_DRAWER} />
              </div>
            </div>
          )}
          <CardMain
            title={paidPerYear}
            border={paidPerYearBorder}
            margin={paidPerYearMargin}
            panel={
              <div className="flex flex-col justify-center">
                {full && (
                  <div className=" text-center font-semibold text-red-500 ">
                    เต็มสิทธิ
                  </div>
                )}
                <div className=" md:text-lg  text-md flex justify-center items-center gap-2.5 ">
                  <div>จำนวนเงิน</div>

                  <NumericFormat
                    disabled={disabled}
                    maxLength={10}
                    value={valueDisplay ? valueDisplay : value}
                    placeholder="0"
                    onChange={onChange}
                    thousandSeparator=","
                    inputMode="decimal"
                    pattern="[0-9],*"
                    className={
                      error
                        ? " px-[14px] py-2 placeholder:text-gray80 md:text-lg text-sm font-semibold focus:outline-none md:w-40  w-28 h-8 rounded-[8px] border border-red-500 text-red-500 "
                        : STYLE_INPUT_INSET
                    }
                  />

                  <div>บาท</div>
                </div>

                {error && (
                  <div className=" md:text-lg  text-xs  md:pt-2 pt-1 text-red-500 pl-4 font-medium text-center mt-1">
                    {errortext && !reverse && (
                      <div>ไม่เกิน {numeral(errortext).format("0,0")} บาท</div>
                    )}
                  </div>
                )}
                {panel2}
              </div>
            }
            etc={etc}
            errorstyle1={errorstyle1}
            errorstyle2={errorstyle2}
            textetc={textetc}
            textetc2={textetc2}
          />

          {!reverse && etc && (
            <div className=" md:text-[16px] text-xs mt-3 text-mainblue font-medium leading-relaxed ">
              <div className={errorstyle1 ? "text-red-500" : ""}>
                {" "}
                * {textetc}
              </div>
              {textetc2 && (
                <div className={errorstyle2 ? "text-red-500 mt-3" : " mt-3"}>
                  {" "}
                  ** <span>{textetc2}</span>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
      <PopupMain
        img={null}
        onClick={() => {
          setOpenInfoPopUp(false);
        }}
        open={openInfoPopUp}
        text={
          <div className=" flex flex-col items-start w-full px-[20px] text-left text-sm ">
            <div className="font-medium">{infoPopUpHeader}</div>
            <div className="font-normal">{infoPopUpText}</div>
          </div>
        }
      />
    </div>
  );
};

export const PanelSumTaxDetails = ({
  title,
  value,
  value2,
  total,
  total_name = "รวม",
  head_1 = "รายการ",
  head_2 = "จำนวนเงินที่ได้รับ (บาท)",
  positionHead = "text-left ",
  subHead = false,
  subhead_name,
  positiontitle = "text-left",
  positionnvalue_1 = "text-right",
  positionnvalue_2 = "text-right",
  etc,
  etc_text,
  cols = 3,
}) => {
  return (
    <div>
      <div className={` ${STYLE_PADDING_BALANCE_DRAWER}`}>
        <div
          className={` grid grid-cols-${cols} mb-2.5 p-2.5 gap-[5px] rounded-[10px] text-mainblue text-sm font-semibold  bg-blueopacity mt-2`}
        >
          {cols === 2 ? "" : <div className={""}>{}</div>}
          <div className={`${positionHead} ${cols === 2 ? "" : "text-right"} `}>
            {head_1}
          </div>
          <div className="text-right font-medium">{head_2}</div>
        </div>
        {subHead ? (
          <div className="  px-2.5 text-base font-semibold mb-1">
            {subhead_name}
          </div>
        ) : (
          ""
        )}
        <div
          className={` grid grid-cols-${cols} gap-[5px] text-sm px-2.5 font-medium`}
        >
          <div className={`${positiontitle}  font-semibold  `}>{title}</div>

          <div className={`${positionnvalue_1} text-base font-semibold`}>
            {value}
          </div>
          {cols === 2 ? (
            ""
          ) : (
            <div className={`${positionnvalue_2} text-base font-semibold`}>
              {value2}
            </div>
          )}
        </div>
        <div className=" grid grid-cols-2 gap-[5px] items-center text-sm px-2.5 mt-10 font-medium">
          <div>{total_name}</div>
          <div className=" text-right text-lg font-bold text-mainblue">
            {total} บาท
          </div>
        </div>
        {etc && (
          <div className=" text-right text-xs text-maingray">{etc_text}</div>
        )}
      </div>
    </div>
  );
};

export const PanelExceptions = ({
  total,
  totalException,
  total_name = "รวม",
  head_1 = "รายการ",
  head_2 = "จำนวนเงินที่ได้รับ (บาท)",
  positionHead = "text-left ",
  subHead = false,
  subhead_name,
  positiontitle = "text-left",
  positionnvalue_1 = "text-right",
  positionnvalue_2 = "text-right",
  displayPart = ["Deduction", "Exception"],
  etc,
  etc_text,
  cols = 3,
  data = [],
  ExceptionLists = [],
  form = {},
}) => {
  const [openInfoPopUp, setOpenInfoPopUp] = useState(false);
  const [openPopUpDonate, setOpenPopUpDonate] = useState(false);
  let sumDeduction = 0;

  const DonateMain = ({ amount = 0, incomeAfter = 0, limit = 0 }) => {
    return (
      <>
        <PopupMain
          img={null}
          onClick={() => {
            setOpenInfoPopUp(false);
          }}
          open={openInfoPopUp}
          text={
            <div className=" flex flex-col items-start w-full px-[20px] text-left text-sm ">
              <div className="font-medium">
                {"เงินบริจาคสนับสนุนการศึกษา/สถานพยาบาล/สภากาชาดไทย/อื่น ๆ"}
              </div>
              <div className="font-normal">
                {`หักลดหย่อนได้ 2 เท่าของจำนวนเงินที่จ่ายไปจริง (${numeral(
                  amount
                ).format("0,0")})
                แต่ไม่เกินร้อยละ 10 ของ เงินได้หลังหักค่าใช้จ่ายและค่าลดหย่อน
                (${numeral(incomeAfter).format(
                  "0,0"
                )}) เป็นจำนวนเงินที่ได้รับสิทธิ ${numeral(limit).format(
                  "0,0"
                )} บาท`}
              </div>
            </div>
          }
        />
      </>
    );
  };

  const DonateOther = ({ amount = 0, incomeAfter = 0, limit = 0 }) => {
    return (
      <>
        <PopupMain
          img={null}
          onClick={() => {
            setOpenPopUpDonate(false);
          }}
          open={openPopUpDonate}
          text={
            <div className=" flex flex-col items-start w-full px-[20px] text-left text-sm ">
              <div className="font-medium">{"เงินบริจาคอื่น ๆ"}</div>
              <div className="font-normal">
                {` หักลดหย่อนได้ตามจำนวนเงินที่จ่ายไปจริง (${numeral(
                  amount
                ).format("0,0")})
                แต่ไม่เกินร้อยละ 10
                ของเงินได้หลังหักค่าใช้จ่ายและค่าลดหย่อนหลังจากเงินบริจาคสนับสนุนการศึกษา/สถานพยาบาล/สภากาชาดไทย/อื่นๆ
                (${numeral(incomeAfter).format(
                  "0,0"
                )}) เป็นจำนวนเงินที่ได้รับสิทธิ ${numeral(limit).format(
                  "0,0"
                )}  บาท`}
              </div>
            </div>
          }
        />
      </>
    );
  };


  return (
    <>
      <div>
        <div className={` ${STYLE_PADDING_BALANCE_DRAWER}`}>
          {displayPart.includes("Deduction") && (
            <>
              <div
                className={` grid grid-cols-${cols} mb-2.5 p-2.5 gap-[5px] rounded-[10px] text-mainblue text-sm font-semibold  bg-blueopacity mt-2`}
              >
                {cols === 2 ? (
                  ""
                ) : (
                  <div className={""}>{"รายละเอียดค่าลดหย่อน"}</div>
                )}
                <div
                  className={`${positionHead} ${
                    cols === 2 ? "" : "text-right"
                  } `}
                >
                  {head_1}
                </div>
                <div className="text-right font-medium">{head_2}</div>
              </div>
              {subHead ? (
                <div className="  px-2.5 text-base font-semibold mb-1">
                  {subhead_name}
                </div>
              ) : (
                ""
              )}
              {data.map((item, index) => {
                

                if (ExceptionLists.includes(item?.title)) {
                  return;
                }

                if (item?.title === "เงินสะสมกองทุนสำรองเลี้ยงชีพ") {
                  return (
                    <div
                      key={index}
                      className={` grid grid-cols-${cols} gap-[5px] text-sm px-2.5 mb-[10px] font-medium`}
                    >
                      <div className={`${positiontitle}  font-semibold  `}>
                        {item?.title}
                      </div>

                      <div
                        className={`${positionnvalue_1} text-base font-semibold`}
                      >
                        {numeral(item?.value).format("0,0")}
                      </div>
                      {cols === 2 ? (
                        ""
                      ) : (
                        <div
                          className={`${positionnvalue_2} text-base font-semibold`}
                        >
                          {item?.value2 >= 10000
                            ? numeral(10000).format("0,0")
                            : numeral(item?.value2).format("0,0")}
                        </div>
                      )}
                    </div>
                  );
                }

                if (
                  item?.title ===
                  "เงินบริจาคสนับสนุนการศึกษา/สถานพยาบาล/สภากาชาดไทย/อื่น ๆ"
                ) {
                  let CalLimit = () => {
                    let tempcalLimit =
                      IncomeAfterCal(
                        form?.tax_income,
                        form?.deduction_expenses,
                        sumDeduction
                      ) * 0.1;
                    if (tempcalLimit < 0) {
                      return 0;
                    }
                    return tempcalLimit;
                  };

                  return (
                    <>
                      <DonateMain
                        amount={item?.value}
                        incomeAfter={
                          IncomeAfterCal(
                            form?.tax_income,
                            form?.deduction_expenses,
                            sumDeduction
                          ) >= 0
                            ? IncomeAfterCal(
                                form?.tax_income,
                                form?.deduction_expenses,
                                sumDeduction
                              )
                            : 0
                        }
                        limit={
                          item?.value * 2 > CalLimit()
                            ? CalLimit()
                            : item?.value * 2
                        }
                      />
                      <div
                        key={index}
                        className={` grid grid-cols-${cols} gap-[5px] text-sm px-2.5 mb-[10px] font-medium`}
                      >
                        <div
                          className={`${positiontitle}  font-semibold flex `}
                        >
                          <div>{item?.title}</div>
                          <div className="w-4 h-4 ">
                            <ICON_INFO
                              onClick={() => {
                                setOpenInfoPopUp(!openInfoPopUp);
                              }}
                              className={` w-4 h-4 text-maingray mt-1`}
                            />
                          </div>
                        </div>

                        <div
                          className={`${positionnvalue_1} text-base font-semibold`}
                        >
                          {numeral(item?.value).format("0,0")}
                        </div>
                        {cols === 2 ? (
                          ""
                        ) : (
                          <div
                            className={`${positionnvalue_2} text-base font-semibold`}
                          >
                            {numeral(item?.value2).format("0,0")}
                          </div>
                        )}
                      </div>
                    </>
                  );
                }

                if (item?.title === "เงินบริจาคอื่น ๆ") {
                  let calLimit = () => {
                    let tempcalLimit =
                      IncomeAfterCal(
                        form?.tax_income,
                        form?.deduction_expenses,
                        sumDeduction
                      ) -
                      data.find(
                        (item) =>
                          item?.title ===
                          "เงินบริจาคสนับสนุนการศึกษา/สถานพยาบาล/สภากาชาดไทย/อื่น ๆ"
                      )?.value2;
                    if (tempcalLimit < 0) {
                      return 0;
                    }
                    return tempcalLimit;
                  };

                  return (
                    <>
                      <DonateOther
                        amount={item?.value}
                        incomeAfter={calLimit()}
                        limit={
                          calLimit() * 0.1 < item?.value2
                            ? calLimit() * 0.1
                            : item?.value2
                        }
                      />
                      <div
                        key={index}
                        className={` grid grid-cols-${cols} gap-[5px] text-sm px-2.5 mb-[10px] font-medium`}
                      >
                        <div
                          className={`${positiontitle}  font-semibold flex `}
                        >
                          <div>{item?.title}</div>
                          <div className="w-4 h-4 items-top  ">
                            <ICON_INFO
                              onClick={() => {
                                setOpenPopUpDonate(!openPopUpDonate);
                              }}
                              className={` w-4 h-4 text-maingray mt-1`}
                            />
                          </div>
                        </div>

                        <div
                          className={`${positionnvalue_1} text-base font-semibold`}
                        >
                          {numeral(item?.value).format("0,0")}
                        </div>
                        {cols === 2 ? (
                          ""
                        ) : (
                          <div
                            className={`${positionnvalue_2} text-base font-semibold`}
                          >
                            {numeral(item?.value2).format("0,0")}
                          </div>
                        )}
                      </div>
                    </>
                  );
                }

                sumDeduction += Number(item?.value2)
                  
                return (
                  <div
                    key={index}
                    className={` grid grid-cols-${cols} gap-[5px] text-sm px-2.5 mb-[10px] font-medium`}
                  >
                    <div className={`${positiontitle}  font-semibold  `}>
                      {item?.title}
                    </div>

                    <div
                      className={`${positionnvalue_1} text-base font-semibold`}
                    >
                      {item?.value === ""
                        ? ""
                        : numeral(item?.value).format("0,0")}
                    </div>
                    {cols === 2 ? (
                      ""
                    ) : (
                      <div
                        className={`${positionnvalue_2} text-base font-semibold`}
                      >
                        {numeral(item?.value2).format("0,0")}
                      </div>
                    )}
                  </div>
                );
              })}
              <div className=" grid grid-cols-2 gap-[5px] items-center text-sm px-2.5 mt-10 font-medium">
                <div>{total_name}</div>
                <div className=" text-right text-lg font-bold text-mainblue">
                  {total} บาท
                </div>
              </div>
            </>
          )}

          {displayPart.includes("Exception") && (
            <>
              <div
                className={` grid grid-cols-${cols} mb-2.5 p-2.5 gap-[5px] mt-[30px] rounded-[10px] text-mainblue text-sm font-semibold  bg-blueopacity `}
              >
                {cols === 2 ? (
                  ""
                ) : (
                  <div className={""}>
                    {"รายละเอียดเงินได้ที่ได้รับการยกเว้น"}
                  </div>
                )}
                <div
                  className={`${positionHead} ${
                    cols === 2 ? "" : "text-right"
                  } `}
                >
                  {head_1}
                </div>
                <div className="text-right font-medium">{head_2}</div>
              </div>
              {data.map((item, index) => {
                if (
                  ExceptionLists.includes(item?.title) &&
                  item?.value !== 0 &&
                  item?.value !== undefined
                ) {
                  return (
                    <div
                      key={index}
                      className={` grid grid-cols-${cols} gap-[5px] text-sm px-2.5 mb-[10px] font-medium`}
                    >
                      <div className={`${positiontitle}  font-semibold  `}>
                        {item?.title}
                      </div>

                      <div
                        className={`${positionnvalue_1} text-base font-semibold`}
                      >
                        {item?.value === ""
                          ? ""
                          : numeral(item?.value).format("0,0")}
                      </div>
                      {cols === 2 ? (
                        ""
                      ) : (
                        <div
                          className={`${positionnvalue_2} text-base font-semibold`}
                        >
                          {numeral(item?.value2).format("0,0")}
                        </div>
                      )}
                    </div>
                  );
                }

                if (item?.title === "เงินสะสมกองทุนสำรองเลี้ยงชีพ") {
                  return (
                    <div
                      key={index}
                      className={` grid grid-cols-${cols} gap-[5px] text-sm px-2.5 mb-[10px] font-medium`}
                    >
                      <div className={`${positiontitle}  font-semibold  `}>
                        {item?.title}
                      </div>

                      <div
                        className={`${positionnvalue_1} text-base font-semibold`}
                      >
                        {numeral(item?.value).format("0,0")}
                      </div>
                      {cols === 2 ? (
                        ""
                      ) : (
                        <div
                          className={`${positionnvalue_2} text-base font-semibold`}
                        >
                          {item?.value2 >= 10000
                            ? numeral(item?.value2 - 10000).format("0,0")
                            : numeral(item?.value2).format("0,0")}
                        </div>
                      )}
                    </div>
                  );
                }
              })}
              <div className=" grid grid-cols-2 gap-[5px] items-center text-sm px-2.5 mt-10 font-medium">
                <div>{total_name}</div>
                <div className=" text-right text-lg font-bold text-mainblue">
                  {totalException} บาท
                </div>
              </div>
            </>
          )}
          {etc && (
            <div className=" text-right text-xs text-maingray">{etc_text}</div>
          )}
        </div>
      </div>
    </>
  );
};

export const PanelNetIncome = ({
  income,
  expense,
  exception,
  deduct,
  total,
}) => {
  return (
    <div>
      <div className={` ${STYLE_PADDING_BALANCE_DRAWER}`}>
        <div
          className={` grid grid-cols-2 mb-2.5 p-2.5 gap-[5px] rounded-[10px] text-mainblue text-sm font-semibold  bg-blueopacity mt-2`}
        >
          <div className={` font-medium `}>{"รายการ"}</div>
          <div className=" text-right font-medium">{"จำนวน"}</div>
        </div>

        <div
          className={` grid grid-cols-2 gap-[5px] text-sm px-2.5 font-medium`}
        >
          <div className={` font-semibold  `}>{"รายได้"}</div>
          <div className={` text-base font-semibold text-right`}>{income}</div>
          <div className={` font-semibold  `}>{"หักค่ายกเว้น"}</div>
          <div className={` text-base font-semibold text-right`}>
            {exception}
          </div>
          <div className={` font-semibold  `}>{"หักค่าใช้จ่าย"}</div>
          <div className={` text-base font-semibold text-right`}>{expense}</div>
          <div className={` font-semibold  `}>{"หักค่าลดหย่อน"}</div>
          <div className={` text-base font-semibold text-right`}>{deduct}</div>
        </div>
        <div className=" grid grid-cols-2 gap-[5px] items-center text-sm px-2.5 mt-10 font-medium">
          <div>{"เงินได้สุทธิ"}</div>
          <div className=" text-right text-lg font-bold text-mainblue">
            {total} บาท
          </div>
        </div>
        <div className=" text-right text-xs text-maingray mt-2.5">
          {" "}
          * ({income} - {exception} - {expense} - {deduct} = {total})
        </div>
      </div>
    </div>
  );
};

export const PanelDeduct = ({ deductList, deductionAFList, total }) => {
  return (
    <div>
      <div className={` ${STYLE_PADDING_BALANCE_DRAWER}`}>
        <div
          className={` grid grid-cols-2 mb-2.5 p-2.5 gap-[5px] rounded-[10px] text-mainblue text-sm font-semibold  bg-blueopacity mt-2`}
        >
          <div className={` font-medium `}>{"รายการ"}</div>
          <div className=" text-right font-medium">{"จำนวน"}</div>
        </div>

        {/*---- รายการค่าลดหย่อน ----*/}
        {deductList?.length > 0 ? (
          <div className="">
            <div className={`text-mainblue text-sm  font-medium mb-1.5`}>
              รายการค่าลดหย่อน
            </div>
            {deductList?.map((item, index) => {
              return (
                <>
                  <div
                    key={index}
                    className={` grid grid-cols-5 items-center gap-[5px] text-sm px-2.5 font-medium mb-0.5`}
                  >
                    <div className={` col-span-3 font-semibold`}>
                      {item.title}
                    </div>
                    <div
                      className={` col-span-2 text-base font-semibold text-right`}
                    >
                      {numeral(item.amount)?.format("0,0")}
                    </div>
                  </div>
                </>
              );
            })}
          </div>
        ) : (
          ""
        )}

        {/*---- รายการค่าลดหย่อนที่วางแผน เพิ่มเติม ----*/}
        {deductionAFList?.length > 0 ? (
          <div className="mt-2.5">
            <div className={`text-mainblue text-sm  font-medium mb-1.5`}>
              รายการค่าลดหย่อนที่วางแผน เพิ่มเติม
            </div>
            {deductionAFList?.map((item, index) => {
              return (
                <>
                  <div
                    key={index}
                    className={` grid grid-cols-5 items-center gap-[5px] text-sm px-2.5 font-medium mb-0.5`}
                  >
                    <div className={` col-span-3 font-semibold`}>
                      {item.title}
                    </div>
                    <div
                      className={` col-span-2 text-base font-semibold text-right`}
                    >
                      {numeral(item.amount)?.format("0,0")}
                    </div>
                  </div>
                </>
              );
            })}
          </div>
        ) : (
          ""
        )}

        <div className=" grid grid-cols-2 gap-[5px] items-center text-sm px-2.5 mt-10 font-medium">
          <div>รวม</div>
          <div className=" text-right text-lg font-bold text-mainblue">
            {total} บาท
          </div>
        </div>
      </div>
    </div>
  );
};
