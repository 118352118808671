// //
//
import OtpInput from "react-otp-input";
import { ICON_CALENDAR } from "../../utils/useIcons";
import "./inputDate.css";

export const InputWithLabel = ({
  type = "text",
  placeholder = "",
  className = "",
  value,
  onChange,
  label,
  disabled = false,
}) => {
  return (
    <div>
      <label className=" text-base text-mainblue font-semibold ">{label}</label>
      <input
        disabled={disabled}
        type={type}
        placeholder={placeholder}
        className={`${className} px-[14px] w-full border border-gray-200 rounded-full h-10 mt-[2px]`}
        value={value}
        onChange={onChange}
        onWheel={(e) => e?.target?.blur()}
      />
    </div>
  );
};

export const InputMain = ({
  type = "text",
  placeholder = "",
  className = "",
  value,
  onChange,
  rounded = "rounded-full",
  margintop = "mt-[2px]",
  disabled = false,
}) => {
  return (
    <input
      type={type}
      placeholder={placeholder}
      disabled={disabled}
      className={`${className} px-[14px] w-full border border-gray-200 ${rounded} h-10 ${margintop}`}
      value={value}
      onChange={onChange}
      onWheel={(e) => e?.target?.blur()}
    />
  );
};

export const InputPasswordMain = ({
  placeholder = "",
  className = "",
  value,
  onChange,
  passwordShown,
}) => {
  return (
    <input
      type={passwordShown ? "text" : "password"}
      placeholder={placeholder}
      className={`${className} pl-[14px] pr-[36px] w-full h-full border border-gray-200 rounded-full `}
      value={value}
      onChange={onChange}
      onWheel={(e) => e?.target?.blur()}
    />
  );
};

export const InputNumberOTP = ({ otp, setOtp }) => {
  return (
    <OtpInput
      value={otp}
      onChange={setOtp}
      numInputs={6}
      containerStyle={"flex justify-center gap-2.5"}
      inputStyle={{
        width: "46px",
        height: "46px",
        backgroundColor: "#f9f9f9",
        border: "1px solid #EAEAEA",
        borderRadius: "0.375rem",
      }}
      inputType="number"
      // renderSeparator={<span>-</span>}
      renderInput={(props) => <input {...props} />}
    />
  );
};
