import { useMemo } from "react";
import { useBalanceStore } from "../../_store/balanceStore";

export const SumDebtInvestValue = () => {
  const balanceData = useBalanceStore((state) => state.balanceData);
  const carLists = balanceData?.debt_long_list
  ?.map((item) => item?.debt_long_cars)
  .flat()
  .filter((item) => item?.personal_or_investment === "investment")
  .reduce((acc, item) => acc + item?.current_price, 0);

  const placeLists = balanceData?.debt_long_list
  ?.map((item) => item?.debt_long_places)
  .flat()
  .filter((item) => item?.personal_or_investment === "investment")
  .reduce((acc, item) => acc + item?.current_price, 0);

  const otherLists = balanceData?.debt_long_list
      ?.map((item) => item?.debt_long_others)
      .flat()
      .filter((item) => item?.personal_or_investment === "investment")
      .reduce((acc, item) => acc + item?.current_price, 0);

  // console.log("sum",carLists+placeLists+otherLists)
  return carLists+placeLists+otherLists
};
