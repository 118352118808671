import { useNavigate } from "react-router-dom";
import { HeaderTitle } from "../../../components/header/Header";
import Container from "../../../components/layout/Container";
import { NavigatePath } from "../../../functions/Navigate.Goback";
import {
  STYLE_MARGIN_TOP_PARENT,
  STYLE_PADDING_MAIN,
  STYLE_TAB,
} from "../../../utils/useStyle";
import React, { useEffect, useState } from "react";
import { Step, Stepper } from "@material-tailwind/react";

import ViewFamily_StepOne from "./ViFamily_StepOne";
import { MTButton } from "../../../components/button/MTButton";
import ViewFamily_StepTwo from "./ViFamily_StepTwo";
import ViewFamily_StepThree from "./ViFamily_StepThree";
import ViewFamily_Final from "./VIFamily_Final";
import { useInsuranceStore } from "../../../_store/insuranceStore";
import { numberWithoutComma } from "../../balance/component/VIDrawerDebt.Main";
import _ from "lodash";
import { defaultValue } from "../../../functions/main";
import { useBalanceStore } from "../../../_store/balanceStore";
import { useEducationStore } from "../../../_store/educationStore";
import { useUserStore } from "../../../_store/userStore";
import {
  calculateRateDifference,
  PV,
  round,
} from "../../../utils/useFunctions";
import { useRetireStore } from "../../../_store/retireStore";

const ViewFamilyInsurance = () => {
  const navigate = useNavigate();
  const [tab, setTab] = useState(0);
  const [final, setFinal] = useState(false);
  const [firstLoading, setFirstLoading] = useState(true);
  const [isLastStep, setIsLastStep] = useState(false);
  const [isFirstStep, setIsFirstStep] = useState(false);

  const insuranceData = useInsuranceStore((state) => state.insuranceData);
  const setInsuranceData = useInsuranceStore((state) => state.setInsuranceData);
  const educationData = useEducationStore((state) => state.educationData);
  const balanceData = useBalanceStore((state) => state.balanceData);
  const userData = useUserStore((state) => state.userData);
  const retireData = useRetireStore((state) => state.retireData);

  useEffect(() => {
    if (!firstLoading) return;
    let localInsuranceData = { ...insuranceData };
    let income_salary = 0;
    let income_bonus = 0;
    let personal_expenses_saving = 0;
    let personal_expenses_tax = 0;
    let personal_expenses_life_fund = 0;
    let personal_expenses_social_insurance = 0;
    let personal_expenses_consumption = 0;
    let personal_expenses_food = 0;
    let personal_expenses_entertainment = 0;
    let personal_expenses_travel = 0;
    let personal_expenses_debt = sumExpensesDebtPerYear();
    let personal_expenses_life_insurance = 0;
    let asset_insurance_private = 0;
    let asset_all = 0;

    if (balanceData?.property_asset_list?.length > 0) {
      asset_all += balanceData?.sum_property_asset;
    }

    if (balanceData?.property_intangible_list?.length > 0) {
      asset_all += balanceData?.sum_property_intangible;
    }

    if (balanceData?.property_invest_list?.length > 0) {
      asset_all += balanceData?.sum_property_invest;
    }

    if (balanceData?.expenses_fixed_list?.length > 0) {
      for (const expenses_fixed_item of balanceData?.expenses_fixed_list) {
        if (expenses_fixed_item?.expenses_insuarant_lifes?.length > 0) {
          for (const expenses_insuarant_item of expenses_fixed_item?.expenses_insuarant_lifes) {
            personal_expenses_life_insurance +=
              expenses_insuarant_item.allowance_peryear;
            asset_insurance_private += +expenses_insuarant_item?.insurance_fund;
          }
        }
      }
    }

    if (balanceData?.salary_peryear_list?.length > 0) {
      for (const salary_peryear_item of balanceData?.salary_peryear_list) {
        income_salary += defaultValue(salary_peryear_item?.salary_permonth, 0);
      }
    }

    if (balanceData?.other_income_list?.length > 0) {
      for (const other_income_item of balanceData?.other_income_list) {
        income_bonus += defaultValue(
          other_income_item?.other_income_peryear,
          0
        );
      }
    }

    if (balanceData?.interest_peryear_list?.length > 0) {
      for (const interest_peryear_item of balanceData?.interest_peryear_list) {
        income_bonus += defaultValue(
          interest_peryear_item?.interest_peryear,
          0
        );
      }
    }

    if (balanceData?.expenses_variable_list?.length > 0) {
      for (const expenses_variable_item of balanceData?.expenses_variable_list) {
        if (
          expenses_variable_item.options_expenses_variable === "expenses_tax"
        ) {
          personal_expenses_tax += +expenses_variable_item.pay_peryear;
        }
      }
    }

    if (balanceData?.expenses_fixed_list?.length > 0) {
      for (const expenses_fixed_item of balanceData?.expenses_fixed_list) {
        if (expenses_fixed_item?.expenses_provide_funds?.length > 0) {
          for (const expenses_provide_func_item of expenses_fixed_item.expenses_provide_funds) {
            if (
              expenses_fixed_item.options_expenses_fixed ===
              "expenses_provide_fund"
            ) {
              personal_expenses_life_fund +=
                +expenses_provide_func_item.pay_peryear;
            }
          }
        }
        if (expenses_fixed_item?.expenses_insuarant_socials?.length > 0) {
          for (const expenses_insuarant_socials_item of expenses_fixed_item?.expenses_insuarant_socials) {
            if (
              expenses_fixed_item.options_expenses_fixed ===
              "expenses_insuarant_social"
            ) {
              personal_expenses_social_insurance +=
                +expenses_insuarant_socials_item.pay_peryear;
            }
          }
        }
      }
    }

    if (balanceData?.expenses_variable_list?.length > 0) {
      for (const expenses_variable_item of balanceData?.expenses_variable_list) {
        if (
          expenses_variable_item?.options_expenses_variable ===
          "expenses_consumer"
        ) {
          personal_expenses_consumption += +expenses_variable_item.pay_peryear;
        }
        if (
          expenses_variable_item?.options_expenses_variable === "expenses_food"
        ) {
          personal_expenses_food += +expenses_variable_item.pay_peryear;
        }
        if (
          expenses_variable_item?.options_expenses_variable ===
          "expenses_recreation"
        ) {
          personal_expenses_entertainment +=
            +expenses_variable_item.pay_peryear;
        }
      }
    }

    if (!+insuranceData?.income_salary) {
      localInsuranceData.income_salary = income_salary;
    }
    if (!+insuranceData?.income_bonus) {
      localInsuranceData.income_bonus = income_bonus;
    }
    if (!+insuranceData?.personal_expenses_saving) {
      localInsuranceData.personal_expenses_saving = personal_expenses_saving;
    }
    if (!+insuranceData?.personal_expenses_tax) {
      localInsuranceData.personal_expenses_tax = personal_expenses_tax;
    }
    if (!+insuranceData?.personal_expenses_life_fund) {
      localInsuranceData.personal_expenses_life_fund =
        personal_expenses_life_fund;
    }
    if (!+insuranceData?.personal_expenses_social_insurance) {
      localInsuranceData.personal_expenses_social_insurance =
        personal_expenses_social_insurance;
    }
    if (!+insuranceData?.personal_expenses_consumption) {
      localInsuranceData.personal_expenses_consumption =
        personal_expenses_consumption;
    }
    if (!+insuranceData?.personal_expenses_food) {
      localInsuranceData.personal_expenses_food = personal_expenses_food;
    }
    if (!+insuranceData?.personal_expenses_entertainment) {
      localInsuranceData.personal_expenses_entertainment =
        personal_expenses_entertainment;
    }
    if (!+insuranceData?.personal_expenses_debt) {
      localInsuranceData.personal_expenses_debt = personal_expenses_debt;
    }
    if (!+insuranceData?.personal_expenses_life_insurance) {
      localInsuranceData.personal_expenses_life_insurance =
        personal_expenses_life_insurance;
    }
    if (!+insuranceData?.asset_insurance_private) {
      localInsuranceData.asset_insurance_private = asset_insurance_private;
    }
    if (!+insuranceData?.personal_expenses_travel) {
      localInsuranceData.personal_expenses_travel = personal_expenses_travel;
    }
    // if (!+insuranceData?.asset_all) {
    localInsuranceData.asset_all = asset_all;
    // }
    if (!+insuranceData?.family_year_survive) {
      localInsuranceData.family_year_survive = getFamilyYearSurvive() || 10;
    }
    if (!+insuranceData?.family_year_survive_max) {
      localInsuranceData.family_year_survive_max = 100;
    }
    if (!+insuranceData?.family_income_rate) {
      localInsuranceData.family_income_rate = retireData?.salary_peryear || 5;
    }
    setInsuranceData(localInsuranceData);
    setFirstLoading(false);
  }, [balanceData]);

  useEffect(() => {
    if (!firstLoading) {
      onChangeForm("")({
        target: { value: "" },
      });
    }
  }, [firstLoading]);

  const getFamilyYearSurvive = () => {
    let family_year_survive_items = [];
    if (userData?.family_tree?.length > 0) {
      for (const thisFamilyTree of userData?.family_tree) {
        let should_minus = 99;
        let diff_age = 0;
        if (
          thisFamilyTree?.relation?.value === "child" &&
          thisFamilyTree?.age <= 25
        ) {
          should_minus = 25;
        }
        diff_age = should_minus - thisFamilyTree?.age;
        family_year_survive_items.push(diff_age);
      }
    }

    if (family_year_survive_items.length > 0) {
      family_year_survive_items = family_year_survive_items.sort(
        (a, b) => b - a
      );
      return family_year_survive_items[0];
    }
    return 0;
  };

  const onChangeForm =
    (key, type = "number") =>
    (e) => {
      let value = e.target.value;
      if (type === "number") {
        value = numberWithoutComma(value);
      }

      if (_.isNaN(value)) {
        value = "";
      }

      const newInsurance = key
        ? { ...insuranceData, [key]: value }
        : { ...insuranceData };
      const keys = Object.keys(newInsurance);
      let sum_family_diff_rate = 0;
      let sum_personal_expenses = 0;
      let sum_income = 0;
      let sum_family = 0;
      let sum_family_survive = 0;
      let sum_family_invest = 0;
      let sum_family_remain = 0;
      let sum_asset = 0;
      let sum_insurance_fund = 0;
      let sum_family_five_year = 0;
      let sum_insurance_education = 0;
      let sum_family_expenses = 0;
      let sum_insurance_net = 0;
      let sum_insurance_current = 0;
      let sum_insurance_more = 0;
      let sum_asset_current = 0;
      let sum_insurance_capital = 0;
      let sum_family_debt = balanceData?.debt_propotion;

      if (educationData?.length > 0) {
        for (const educationItem of educationData) {
          const first_insurance = defaultValue(
            educationItem?.education_items?.[0].sum_insurance,
            0
          );
          sum_insurance_education += first_insurance;
        }
      }

      for (const key of keys) {
        if (key.startsWith("personal_expenses")) {
          sum_personal_expenses += defaultValue(+newInsurance[key], 0);
        }
        if (
          key.startsWith("asset") &&
          !["asset_is_social_insurance", "asset_is_debt"].includes(key)
        ) {
          sum_asset += defaultValue(+newInsurance[key], 0);
        }
      }

      if (balanceData?.expenses_fixed_list?.length > 0) {
        for (const expenses_fixed_item of balanceData?.expenses_fixed_list) {
          const keys = Object.keys(expenses_fixed_item);
          for (const key of keys) {
            if (_.isArray(expenses_fixed_item[key])) {
              for (const item_of_key of expenses_fixed_item[key]) {
                sum_insurance_current += +item_of_key.insurance_fund;
                // sum_insurance_current += _.get(
                //   item_of_key.more_details,
                //   "contract_tpd",
                //   0
                // );
                // sum_insurance_current += _.get(
                //   item_of_key.more_details,
                //   "compensation_ecir",
                //   0
                // );
                // sum_insurance_current += _.get(
                //   item_of_key.more_details,
                //   "compensation_aia_supercare",
                //   0
                // );
                // sum_insurance_current += _.get(
                //   item_of_key.more_details,
                //   "compensation_aia_ci_plus_gold",
                //   0
                // );
                // sum_insurance_current += _.get(
                //   item_of_key.more_details,
                //   "compensation_aia_ci_plus",
                //   0
                // );
                // sum_insurance_current += _.get(
                //   item_of_key.more_details,
                //   "compensation_mutiple_care_ci",
                //   0
                // );
              }
            }
          }
        }
      }

      if (
        key === "family_income_rate" &&
        newInsurance[key] > newInsurance.family_income_rate_max
      ) {
        newInsurance[key] = newInsurance.family_income_rate_max;
      }
      if (
        key === "family_investment_rate" &&
        newInsurance[key] > newInsurance.family_investment_rate_max
      ) {
        newInsurance[key] = newInsurance.family_investment_rate_max;
      }

      newInsurance.income_salary_per_year =
        defaultValue(newInsurance.income_salary, 0) * 12;
      sum_family_diff_rate = calculateRateDifference(
        newInsurance.family_investment_rate,
        newInsurance.family_income_rate
      );
      sum_income =
        defaultValue(newInsurance.income_salary_per_year, 0) +
        defaultValue(newInsurance.income_bonus, 0);
      sum_family = round(
        PV(
          sum_family_diff_rate / 100,
          newInsurance.family_year_survive,
          +sum_income - +sum_personal_expenses
        ),
        0
      );
      sum_family_five_year = round(
        PV(
          sum_family_diff_rate / 100,
          5,
          +sum_income - +sum_personal_expenses
        ),
        0
      );

      // C23*(1+J24)^J23
      sum_family_survive =
        sum_family *
        (1 + defaultValue(newInsurance.family_income_rate, 0) / 100) **
          newInsurance.family_year_survive;

      sum_family_invest =
        sum_family *
        (1 + defaultValue(newInsurance.family_investment_rate, 0) / 100) **
          newInsurance.family_year_survive;

      if (
        newInsurance.family_investment_rate > newInsurance.family_income_rate
      ) {
        sum_family_remain = sum_family_survive;
      } else {
        sum_family_remain =
          sum_family_survive + (sum_family_survive - sum_family_invest);
      }

      sum_insurance_current =
        +sum_insurance_current +
        +newInsurance.asset_insurance_private +
        +newInsurance.asset_insurance_group +
        +newInsurance.asset_invest_rmf;

      sum_asset_current = newInsurance.asset_all;

      if (newInsurance.asset_is_social_insurance === "Y") {
        newInsurance.asset_social_dead_contribution = 75000;
        newInsurance.asset_social_dead = 40000;
        sum_asset_current +=
          newInsurance.asset_social_retire +
          newInsurance.asset_social_dead +
          newInsurance.asset_social_dead_contribution;
      } else {
        newInsurance.asset_social_retire = 0;
        newInsurance.asset_social_dead = 0;
        newInsurance.asset_social_dead_contribution = 0;
      }

      // sum_family_remain = sum_family;
      // sum_family_survive = sum_family;
      sum_insurance_fund = sum_family_remain - sum_asset;

      sum_family_expenses =
        defaultValue(+sum_income, 0) - defaultValue(+sum_personal_expenses, 0);
      // sum_family_five_year = sum_family_expenses * 5;

      if (newInsurance?.asset_is_debt === "N") {
        sum_insurance_education = 0;
        sum_family_debt = 0;
      }
      sum_insurance_net =
        // sum_family_remain + sum_insurance_education + sum_family_debt;
        sum_family + sum_insurance_education + sum_family_debt;
      sum_insurance_more =
        sum_family - sum_asset + sum_insurance_education + sum_family_debt;
      // sum_insurance_net - sum_insurance_current - sum_asset_current;
      sum_insurance_capital = round(Math.abs(sum_family) - sum_asset, 0);

      setInsuranceData({
        ...newInsurance,
        sum_insurance_capital,
        sum_family_diff_rate,
        sum_family_debt,
        sum_personal_expenses,
        sum_income,
        sum_family,
        sum_family_survive,
        sum_family_invest,
        sum_family_remain,
        sum_asset,
        sum_insurance_fund,
        sum_family_five_year,
        sum_insurance_education,
        sum_family_expenses,
        sum_insurance_net,
        sum_insurance_current,
        sum_insurance_more,
        sum_asset_current,
      });
    };

  // หายอดรวมค่าใช้จ่ายหนี้สินรายเดือนทั้งหมด
  const sumExpensesDebtPerMonth = () => {
    let result = 0;
    if (balanceData?.sum_debt_short_permonth) {
      result += balanceData?.sum_debt_short_permonth;
    }
    if (balanceData?.sum_debt_long_permonth) {
      result += balanceData?.sum_debt_long_permonth;
    }
    return result;
  };
  // หายอดรวมค่าใช้จ่ายหนี้สินรายปีทั้งหมด
  const sumExpensesDebtPerYear = () => {
    let result = 0;
    result = sumExpensesDebtPerMonth() * 12;
    return result;
  };

  return (
    <div>
      <Container>
        <div className=" mb-10">
          <div className={`${STYLE_MARGIN_TOP_PARENT} `}>
            <HeaderTitle
              title="ทุนประกันคุ้มครองเพื่อครอบครัว"
              isGoback
              onClickGoBack={() => {
                if (final) {
                  setFinal(false);
                  setTab(2);
                  return;
                }
                navigate(-1);
              }}
            />
            {final ? (
              <ViewFamily_Final setFinal={setFinal} setTab={setTab} />
            ) : (
              <>
                <div className={` ${STYLE_PADDING_MAIN}`}>
                  {/* ------ หัวข้อ Step ------ */}
                  <div className=" px-4">
                    <Stepper
                      activeStep={tab}
                      lineClassName="bg-gray-300"
                      activeLineClassName="bg-maincyan"
                      className={`${STYLE_TAB} `}
                      isLastStep={(value) => setIsLastStep(value)}
                      isFirstStep={(value) => setIsFirstStep(value)}
                    >
                      <Step
                        className=" !bg-maincyan text-lg"
                        activeClassName="!bg-maincyan "
                        onClick={() => setTab(0)}
                      >
                        <div className=" mt-0.5">1</div>
                      </Step>
                      <Step
                        onClick={() => setTab(1)}
                        className={`${
                          tab === 0 ? "!bg-gray300" : "!bg-maincyan"
                        }`}
                        activeClassName="!bg-maincyan"
                      >
                        <div className=" mt-0.5">2</div>
                      </Step>

                      <Step
                        className="!bg-gray-300"
                        activeClassName="!bg-maincyan"
                        onClick={() => setTab(2)}
                      >
                        <div className=" mt-0.5">3</div>
                      </Step>
                    </Stepper>
                  </div>
                </div>
                {tab === 0 && (
                  <ViewFamily_StepOne onChangeForm={onChangeForm} />
                )}
                {tab === 1 && (
                  <ViewFamily_StepTwo onChangeForm={onChangeForm} />
                )}
                {tab === 2 && (
                  <ViewFamily_StepThree onChangeForm={onChangeForm} />
                )}
                <div className=" flex justify-center gap-4 mb-10 px-5">
                  {tab === 0 ? (
                    <div>
                      <MTButton
                        // loading={loading}
                        text={"ถัดไป"}
                        color="bg-maincyan"
                        onClick={(e) => {
                          setTab((prev) => prev + 1);
                        }}
                      />
                    </div>
                  ) : (
                    <div>
                      {tab === 3 ? (
                        ""
                      ) : (
                        <div className=" flex justify-center items-center gap-2">
                          <MTButton
                            // loading={loading}
                            text="ย้อนกลับ"
                            color="border border-maincyan "
                            colortext="text-maincyan group-hover:text-white"
                            hovercolor="hover:bg-maincyan group "
                            onClick={() => {
                              setTab((prev) => prev - 1);
                            }}
                          />
                          <MTButton
                            // loading={loading}
                            text={"ถัดไป"}
                            color="bg-maincyan"
                            onClick={(e) => {
                              if (tab === 2) {
                                setFinal(true);
                                setInsuranceData({
                                  ...insuranceData,
                                  is_plan: true,
                                });
                                return;
                              }
                              setTab((prev) => prev + 1);
                            }}
                          />
                        </div>
                      )}
                    </div>
                  )}
                </div>
              </>
            )}
          </div>
        </div>
      </Container>
    </div>
  );
};

export default ViewFamilyInsurance;
