import _ from "lodash";
import numeral from "numeral";
import { useMemo } from "react";
import { useBalanceStore } from "../../../_store/balanceStore";
import {
  calDebtCreditCard,
  calTotalCreditorFloatInterest,
  cn,
  generateAmortizationSchedule,
  mapDebtConditionColor,
  round,
  toFloat2,
} from "../../../utils/useFunctions";
import { OPTIONS_TYPE_CAR, OPTIONS_TYPE_PLACE } from "../../balance/functions";
import { options_debt } from "../../balance/styles/Card";
import {
  formatPeriod,
  formatPeriodTime,
} from "../../debt/component/VIDebtTable";
import { PdfCard, PdfCardGray, PdfCardWhite } from "../component/pdf.c.card";
import PdfFooter from "../component/pdf.c.footer";
import PdfHeader from "../component/pdf.c.header";
import { PdfHeaderText } from "../component/pdf.c.headerText";
import PdfLine from "../component/pdf.c.line";
import {
  STYLE_PAGE,
  STYLE_PDF_TEXT_MEDIUM_12,
  STYLE_PDF_TEXT_MEDIUM_BLUE_11,
  STYLE_PDF_TEXT_MEDIUM_BLUE_12,
  STYLE_PDF_TEXT_MEDIUM_BLUE_13,
  STYLE_PDF_TEXT_NORMAL_BLUE_11,
  STYLE_PDF_TEXT_NORMAL_GRAY_11,
} from "../styles/pdf.style";

function PdfPageDebtTwo({ page }) {
  const balanceData = useBalanceStore((state) => state.balanceData);

  const debtShortItems = useMemo(() => {
    const newDebtShortItems = [];

    if (balanceData?.debt_short_list?.length > 0) {
      for (const debt_short_item of balanceData?.debt_short_list) {
        const keys = Object.keys(debt_short_item);
        for (const key of keys) {
          if (_.isArray(debt_short_item[key])) {
            for (const item of debt_short_item[key]) {
              let createItem = {
                ...debt_short_item,
                ...item,
                title: "",
                unpaid_amount: item?.unpaid_amount,
                pay_permonth: item?.pay_permonth,
                interest: item?.interest,
                period: item?.period,
                period_time: "",
                type_payment: "",
                type_payment_ori: item?.type_payment,
                label: item?.creditor_name,
                status: options_debt.find(
                  (option) => option?.value === item?.status
                ),
                unit_interest: "ต่อปี",
                sort: 0,
                customAmountOfInterest:
                  debt_short_item?.options_debt_short ===
                  "debt_informal_shortterm"
                    ? item?.start_amount * (item?.interest / 100)
                    : item?.unpaid_amount * (item?.interest / 100),
              };

              // เครดิตการ์ด
              if (debt_short_item?.options_debt_short === "debt_creditcard") {
                const creditor = calDebtCreditCard(item);
                const period = creditor.creditor_items.length;
                const typePayment = !item?.type_payment
                  ? ""
                  : item?.type_payment === "pay_minimum"
                  ? "จ่ายขั้นต่ำ(รายเดือน)"
                  : item?.type_payment === "pay_full"
                  ? "จ่ายเต็มจำนวน"
                  : "จ่ายแบบระบุจำนวน(รายเดือน)";
                createItem.period = !item?.type_payment ? "" : period;
                createItem.period_time = !item?.type_payment
                  ? ""
                  : formatPeriodTime(period);
                createItem.type_payment = typePayment;
                createItem.title = "บัตรเครดิต";
                createItem.sort = 1;
                createItem.customAmountOfInterest =
                  item?.type_payment === "pay_full"
                    ? 0
                    : creditor?.total_creditor_interest;
              }

              if (debt_short_item?.options_debt_short === "debt_shortterm") {
                const interest_min = toFloat2(
                  (item.unpaid_amount * item.interest) / 100
                );

                const loanDetails = generateAmortizationSchedule(
                  item.unpaid_amount,
                  item.interest,
                  item.pay_permonth
                );

                const totalInterest = loanDetails.reduce((acc, item) => {
                  return acc + item?.interestPayment;
                }, 0);
                const totalCreditor = round(
                  item?.unpaid_amount + totalInterest,
                  0
                );
                createItem.customAmountOfInterest = totalInterest;
                createItem.sort = 2;
                createItem.type_payment = "รายเดือน";
              } else if (
                debt_short_item?.options_debt_short ===
                "debt_informal_shortterm"
              ) {
                createItem.sort = 3;
                createItem.title = "หนี้นอกระบบ (รายวัน)";
                createItem.type_payment = "รายวัน";
                createItem.period = `${item?.number_of_days} วัน`;
              } else if (
                debt_short_item?.options_debt_short === "debt_shortterm_other"
              ) {
                const creditor = calTotalCreditorFloatInterest(item);
                const totalCreditorInterest = creditor.reduce(
                  (acc, item, index) => {
                    if (index > 11) {
                      return acc;
                    }
                    return acc + item.interest;
                  },
                  0
                );
                createItem.customAmountOfInterest = totalCreditorInterest;
                createItem.sort = 4;
                createItem.type_payment = "รายเดือน";
                createItem.unit_interest = "ต่อเดือน";
              }

              // เงินกู้ระยะสั้น, หนี้นอกระบบ, เงินกู้ระยะสั้นอื่นๆ
              if (
                [
                  "debt_shortterm",
                  "debt_informal_shortterm",
                  "debt_shortterm_other",
                ].includes(debt_short_item?.options_debt_short)
              ) {
                if (debt_short_item?.options_debt_short === "debt_shortterm") {
                  createItem.title = "หนี้กู้ระยะสั้น";
                } else if (
                  debt_short_item?.options_debt_short ===
                  "debt_informal_shortterm"
                ) {
                  createItem.title = "หนี้นอกระบบ";
                }
                createItem.period_time = formatPeriodTime(item?.period);
              }

              if (
                debt_short_item?.options_debt_short ===
                "debt_informal_shortterm"
              ) {
                createItem.unpaid_amount = item?.start_amount;
              }
              newDebtShortItems.push(createItem);
            }
          }
        }
      }
    }

    return newDebtShortItems.sort((a, b) => a.sort - b.sort);
  }, [balanceData]);

  const debtLongItems = useMemo(() => {
    const newDebtLongItems = [];

    if (balanceData?.debt_long_list?.length > 0) {
      for (const debt_long_item of balanceData.debt_long_list) {
        const keys = Object.keys(debt_long_item);
        for (const key of keys) {
          if (_.isArray(debt_long_item[key])) {
            for (const item of debt_long_item[key]) {
              let createItem = {
                unpaid_amount: item?.unpaid_amount,
                pay_permonth: item?.pay_permonth,
                interest: item?.interest,
                period: item?.period,
                period_time: formatPeriodTime(item?.period),
                type_payment: "",
                label: item.title,
              };

              // รถยนต์
              if (debt_long_item?.options_debt_long === "debt_car") {
                const findTypeCar = OPTIONS_TYPE_CAR?.filter((thisType) => {
                  if (thisType.value === item?.type_car) return thisType;
                });
                if (findTypeCar) {
                  createItem.label = `${findTypeCar[0]?.label} ${item?.title_car}`;
                }
              }

              // สินเชื่อที่อยู่อาศัย
              if (debt_long_item?.options_debt_long === "debt_place") {
                const findTypePlace = OPTIONS_TYPE_PLACE?.filter((thisType) => {
                  if (thisType.value === item?.type_place) return thisType;
                });
                const typePlace = item?.type_place_other
                  ? item?.type_place_other
                  : findTypePlace?.[0]?.label;
                if (typePlace) {
                  createItem.label = `${typePlace} (${item?.address_place})`;
                }
              }

              newDebtLongItems.push(createItem);
            }
          }
        }
      }
    }
    return newDebtLongItems;
  }, [balanceData]);

  const mergeDebtItems = useMemo(() => {
    const newMergeDebtItems = [
      {
        label: "หนี้ระยะสั้น",
        items: debtShortItems,
      },
      // {
      //   label: "หนี้ระยะยาว",
      //   items: debtLongItems,
      // },
    ];
    return newMergeDebtItems;
  }, [balanceData]);

  const totalItem = useMemo(() => {
    let total = 0;
    for (const item of [...debtShortItems, ...debtLongItems]) {
      total += item.unpaid_amount;
    }
    return total;
  }, [balanceData]);

  const totalShortItem = useMemo(() => {
    let total = 0;
    for (const item of debtShortItems) {
      total += item.unpaid_amount;
    }
    return total;
  }, [balanceData]);

  const sumTotalWeightInterest = () => {
    let total_unpaid = 0;
    let total_left = 0;
    let total_final = 0;

    if (!balanceData?.debt_short_list) return total_final;

    for (const debt_short_item of balanceData?.debt_short_list) {
      const keys = Object.keys(debt_short_item);
      for (const key of keys) {
        if (_.isArray(debt_short_item[key])) {
          for (const item of debt_short_item[key]) {
            if (
              debt_short_item?.options_debt_short === "debt_informal_shortterm"
            ) {
              total_unpaid += item.start_amount;
              total_left += item.start_amount * item.interest;
            } else if (
              debt_short_item?.options_debt_short === "debt_creditcard" &&
              item?.type_payment === "pay_full"
            ) {
              const creditor = calDebtCreditCard(item);
              total_unpaid += item.unpaid_amount;
              total_left += item.unpaid_amount * 0;
            } else if (
              debt_short_item?.options_debt_short === "debt_shortterm_other"
            ) {
              total_unpaid += item.unpaid_amount;
              total_left += item.unpaid_amount * item.interest * 12;
            } else {
              total_unpaid += item.unpaid_amount;
              total_left += item.unpaid_amount * item.interest;
            }
          }
        }
      }
    }
    total_final = total_left / total_unpaid;
    return total_final;
  };

  const sumTotalInterest = () => {
    let total_unpaid = 0;
    let total_left = 0;

    if (!balanceData?.debt_short_list) return total_left;

    for (const debt_short_item of balanceData?.debt_short_list) {
      const keys = Object.keys(debt_short_item);
      for (const key of keys) {
        if (_.isArray(debt_short_item[key])) {
          for (const item of debt_short_item[key]) {
            if (debt_short_item?.options_debt_short === "debt_shortterm") {
              const interest_min = toFloat2(
                (item.unpaid_amount * item.interest) / 100
              );

              const loanDetails = generateAmortizationSchedule(
                item.unpaid_amount,
                item.interest,
                item.pay_permonth
              );

              const totalInterest = loanDetails.reduce((acc, item) => {
                return acc + item?.interestPayment;
              }, 0);
              total_left += totalInterest;
            }

            if (
              debt_short_item?.options_debt_short === "debt_informal_shortterm"
            ) {
              total_unpaid += item.start_amount;
              total_left += item.start_amount * (item.interest / 100);
            } else if (
              debt_short_item?.options_debt_short === "debt_creditcard"
            ) {
              const creditor = calDebtCreditCard(item);
              total_unpaid += item.unpaid_amount;
              total_left +=
                item?.type_payment === "pay_full"
                  ? 0
                  : creditor?.total_creditor_interest;
            } else if (
              debt_short_item?.options_debt_short === "debt_shortterm_other"
            ) {
              const creditor = calTotalCreditorFloatInterest(item);
              const totalCreditorInterest = creditor.reduce(
                (acc, item, index) => {
                  if (index > 11) {
                    return acc;
                  }
                  return acc + item.interest;
                },
                0
              );
              total_unpaid += item.unpaid_amount;
              total_left += totalCreditorInterest;
            } else {
              total_unpaid += item.unpaid_amount;
              total_left += item.unpaid_amount * (item.interest / 100);
            }
          }
        }
      }
    }
    return total_left;
  };

  return (
    <div className={cn(STYLE_PAGE)}>
      <PdfHeader />
      <PdfLine />
      <PdfHeaderText title="แผนจัดการหนี้สิน" icon="/images/wealth_1.svg" />

      {/* แผนจัดการหนี้สิน SECOND */}
      <PdfCard>
        <PdfCardWhite className={cn("px-3")}>
          <div className={cn(STYLE_PDF_TEXT_MEDIUM_12)}>
            1. รวมหนี้ระยะสั้นเป็นก้อนเดียวกัน
          </div>
          <div
            className={cn(
              "flex justify-between mt-1",
              STYLE_PDF_TEXT_MEDIUM_12
            )}
          >
            <div>จำนวนเงินค้างชำระ</div>
            <div>{numeral(totalShortItem).format("0,0")} บาท</div>
          </div>
          <PdfLine />
          <div className="space-x-2 grid grid-cols-6 items-center">
            <div className="flex space-x-2 items-center col-span-2">
              <div>
                <img src="/images/emoji_bar_chart.svg" />
              </div>
              <div className={cn(STYLE_PDF_TEXT_MEDIUM_12)}>
                สินทรัพย์ไม่มีหลักประกัน
              </div>
            </div>
            <div
              className={cn(STYLE_PDF_TEXT_MEDIUM_12, "text-right col-span-2")}
            >
              จำนวนเงินค้างชำระ
            </div>
            <div className={cn(STYLE_PDF_TEXT_MEDIUM_12, "text-right ")}>
              ดอกเบี้ย(%)
            </div>
            <div className={cn(STYLE_PDF_TEXT_MEDIUM_12, "text-right ")}>
              ดอกเบี้ย(บาท)
            </div>
          </div>

          <PdfCardGray className={cn("mt-2")}>
            {debtShortItems.map((item, index) => {
              let interest = item?.interest;

              let customAmountOfInterest = item?.customAmountOfInterest;
              if (item?.options_debt_short === "debt_creditcard") {
                interest =
                  item?.type_payment_ori === "pay_full" ? 0 : item.interest;
              } else if (item?.options_debt_short === "debt_shortterm_other") {
                interest = item?.interest * 12;
              }
              return (
                <div
                  key={index}
                  className={cn("grid grid-cols-6 justify-between")}
                >
                  <div className={cn(STYLE_PDF_TEXT_MEDIUM_12, "col-span-2")}>
                    {item?.title} {item?.label}
                  </div>
                  <div
                    className={cn(
                      STYLE_PDF_TEXT_MEDIUM_BLUE_12,
                      "text-right col-span-2"
                    )}
                  >
                    {numeral(item.unpaid_amount).format("0,0")} บาท
                  </div>
                  <div
                    className={cn(STYLE_PDF_TEXT_MEDIUM_BLUE_12, "text-right")}
                  >
                    {numeral(interest).format("0")}%
                  </div>
                  <div
                    className={cn(STYLE_PDF_TEXT_MEDIUM_BLUE_12, "text-right")}
                  >
                    {numeral(customAmountOfInterest).format("0,0")}
                  </div>
                </div>
              );
            })}
          </PdfCardGray>
          <div className="grid grid-cols-6 justify-between mt-2">
            <div className={cn(STYLE_PDF_TEXT_MEDIUM_12, "col-span-2")}>
              ยอดรวม
            </div>
            <div
              className={cn(
                STYLE_PDF_TEXT_MEDIUM_BLUE_13,
                "mr-2 col-span-2 text-right"
              )}
            >
              {numeral(totalShortItem).format("number")} บาท
            </div>
            <div
              className={cn(STYLE_PDF_TEXT_MEDIUM_BLUE_13, "mr-2 text-right")}
            >
              {numeral(sumTotalWeightInterest()).format("0,0.00")}%
            </div>
            <div
              className={cn(STYLE_PDF_TEXT_MEDIUM_BLUE_13, "mr-2 text-right")}
            >
              {numeral(sumTotalInterest()).format("number")}
            </div>
          </div>
          
        </PdfCardWhite>
        
      </PdfCard>
      <div className={"w-full  "+STYLE_PDF_TEXT_MEDIUM_12}>
            *หากมีเงินสดเพียงพอ รีบนำไปชำระหนี้ที่ดอกเบี้ยสูง
            หรือเลือกปิดหนี้หลายรายการเลย*
          </div>
      <PdfFooter page={page} />
    </div>
  );
}

export default PdfPageDebtTwo;
