import React, { forwardRef, useEffect, useState } from "react";
import { STYLE_TEXT_BLUE } from "../../utils/useStyle";
import { InputMain } from "../../components/input/InputMain";
import { MTButton } from "../../components/button/MTButton";
import { useLocation, useNavigate } from "react-router-dom";
import Api from "../../api/api.main";
import { PopupMain } from "../../components/popup/PopupMain";
import { useUserStore } from "../../_store/userStore";
import ErrorMain from "../../components/error/Error.Main";

import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import th from "date-fns/locale/th";
import { ICON_CALENDAR } from "../../utils/useIcons";
import { DateTime } from "luxon";
import { range } from "lodash";
import DropDownMain from "../../components/dropdown/DropDownMain";

registerLocale("th", th);

const dataCurrentDate = () => {
  // Create a new Date object
  var currentDate = new Date();
  // Get the current year
  var currentYear = currentDate.getFullYear();

  return { dataDate: currentDate, year: currentYear };
};

const years = range(1890, new Date().getFullYear() + 1, 1);
const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

const monthsThai = [
  "มกราคม",
  "กุมภาพันธ์",
  "มีนาคม",
  "เมษายน",
  "พฤษภาคม",
  "มิถุนายน",
  "กรกฎาคม",
  "สิงหาคม",
  "กันยายน",
  "ตุลาคม",
  "พฤศจิกายน",
  "ธันวาคม",
];

const LIFE_STATUS_LIST = [
  { value: "single", label: "โสด" },
  { value: "married", label: "สมรส" },
  { value: "widowed", label: "หม้าย" },
  { value: "divorce", label: "หย่าร้าง" },
];

const FirstLogin = () => {
  const { search } = useLocation();
  const params = new URLSearchParams(search);
  const currentEmailLogin = params.get("email");

  // หาปีปัจจุบัน
  const dataCurrDate = dataCurrentDate();
  const currentYear = dataCurrDate.year;

  // UserData Logined
  const userData = useUserStore((state) => state.userData);
  const setUserData = useUserStore((state) => state.setUserData);

  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);

  const [openAlert, setOpenAlert] = useState(false);

  // Form data login
  const [formLogin, setFormLogin] = useState({
    firstname: "",
    lastname: "",
    birthday: "",
    age: "",
    sex: "",
    life_status: "",
    phone_number: "",
    tax_id: "",
  });

  // Check Error
  const [errorForm, setErrorForm] = useState({
    // username: { isError: false, message: "" },
    // password: { isError: false, message: "" },
    firstname: { isError: false, message: "" },
    lastname: { isError: false, message: "" },
    birthday: { isError: false, message: "" },
    age: { isError: false, message: "" },
    sex: { isError: false, message: "" },
    life_status: { isError: false, message: "" },
    phone_number: { isError: false, message: "" },
    tax_id: { isError: false, message: "" },
    healing_rights: { isError: false, message: "" },
    referral_code: { isError: false, message: "" },
  });

  // Check Error Year ห้ามเกิดในอนาคต
  const [isErrorYear, setIsErrorYear] = useState(false);

  //---------------------------------------------------------------------------------//

  // Custom Datepicker

  const CustomInputBirthday = forwardRef((props, ref) => {
    const { value, onClick } = props;

    let thaiDate = "";
    if (value) {
      const splitDate = String(value)?.split("/");
      const date = splitDate[0];
      const month = Number(splitDate[1]);
      const year = Number(splitDate[2]) + 543;
      thaiDate = `${date}/${month}/${year}`;
    }

    return (
      <label
        onClick={onClick}
        className=" relative flex items-center rounded-full w-full h-10 mt-[2px]"
      >
        <div className="px-[14px] w-full h-[40px] border border-gray-200 items-center flex rounded-full caret-transparent">
          {value ? (
            thaiDate
          ) : (
            <span className="text-gray-400">วัน/เดือน/ปี (พ.ศ.)</span>
          )}
        </div>
        <ICON_CALENDAR className={" absolute mx-[14px] right-0 w-6 h-6"} />
      </label>
    );
  });

  const CustomInputBirthdayHearder = ({
    date,
    changeYear,
    changeMonth,
    decreaseMonth,
    increaseMonth,
    prevMonthButtonDisabled,
    nextMonthButtonDisabled,
  }) => {
    return (
      <div className="flex items-center gap-5 justify-center">
        <button onClick={decreaseMonth} disabled={prevMonthButtonDisabled}>
          {"<"}
        </button>
        <select
          value={date ? new Date(date).getFullYear() : new Date().getFullYear()}
          onChange={({ target: { value } }) => changeYear(value)}
        >
          {years.map((option) => (
            <option key={option} value={option}>
              {option + 543}
            </option>
          ))}
        </select>

        <select
          value={
            monthsThai[date ? new Date(date).getMonth() : new Date().getMonth()]
          }
          onChange={({ target: { value } }) =>
            changeMonth(monthsThai.indexOf(value))
          }
        >
          {monthsThai.map((option) => (
            <option key={option} value={option}>
              {option}
            </option>
          ))}
        </select>

        <button onClick={increaseMonth} disabled={nextMonthButtonDisabled}>
          {">"}
        </button>
      </div>
    );
  };

  //---------------------------------------------------------------------------------//

  // Username
  const onChangeUsername = (e) => {
    let value = e.target.value;
    return setFormLogin({ ...formLogin, username: value });
  };

  // Password
  const onChangePassword = (e) => {
    let value = e.target.value;
    return setFormLogin({ ...formLogin, password: value });
  };
  const [passwordShown, setPasswordShown] = useState(false);
  const togglePasswordShown = () => {
    setPasswordShown(!passwordShown);
  };

  // Firstname
  const onChangeFirstname = (e) => {
    let value = e.target.value;
    setErrorForm({
      ...errorForm,
      firstname: formLogin.firstname ? false : true,
    });
    return setFormLogin({ ...formLogin, firstname: value });
  };

  // Lastname
  const onChangeLastname = (e) => {
    let value = e.target.value;
    setErrorForm({
      ...errorForm,
      lastname: formLogin.lastname ? false : true,
    });
    return setFormLogin({ ...formLogin, lastname: value });
  };

  // Birthday
  const onChangeBirthday = (date) => {
    let value = date;
    const dateFormated = DateTime.fromJSDate(new Date(value)).toFormat(
      "yyyy-MM-dd"
    );

    setErrorForm({
      ...errorForm,
      birthday: formLogin.birthday ? false : true,
      age: formLogin.age ? false : true,
    });

    let dateArray = String(dateFormated).split("-");
    let year = parseInt(dateArray[0]);

    let currentAge = currentYear - year;

    if (currentAge <= 0) {
      setIsErrorYear(true);
    } else {
      setIsErrorYear(false);
    }

    return setFormLogin({
      ...formLogin,
      birthday: value,
      age: currentAge,
    });
  };

  // Age
  const onChangeAge = (e) => {
    let value = Number(e.target.value.slice(0, 4));
    if (value === 0) {
      return setFormLogin({ ...formLogin, age: "" });
    }
    setErrorForm({
      ...errorForm,
      age: formLogin.age ? false : true,
    });
    return setFormLogin({ ...formLogin, age: value });
  };

  // Sex
  const onChangeSex = (e) => {
    let value = e.target.value;
    setErrorForm({
      ...errorForm,
      sex: formLogin.sex ? false : true,
    });
    return setFormLogin({ ...formLogin, sex: value });
  };

  // Status
  const onChangeSelectedStatus = (data) => {
    let value = data.label;
    setErrorForm({
      ...errorForm,
      life_status: false,
    });
    return setFormLogin({ ...formLogin, life_status: value });
  };

  // Phone Number
  const onChangePhoneNumber = (e) => {
    let value = e.target.value.slice(0, 10);
    setErrorForm({
      ...errorForm,
      phone_number: formLogin.phone_number ? false : true,
    });
    return setFormLogin({ ...formLogin, phone_number: value });
  };
  const validatePhoneNumber = (phoneNumber) => {
    const checkPhoneNumber = /^\d{10}$/;
    const result = checkPhoneNumber.test(phoneNumber);
    return result;
  };

  // TAX ID
  const onChangeTaxId = (e) => {
    let value = e.target.value.slice(0, 13);
    setErrorForm({
      ...errorForm,
      tax_id: false,
    });
    return setFormLogin({ ...formLogin, tax_id: value });
  };

  // Healing Rights
  const onChangeHealingRight = (e) => {
    let value = e.target.value;
    setErrorForm({
      ...errorForm,
      healing_rights: false,
    });
    return setFormLogin({ ...formLogin, healing_rights: value });
  };

  // Referral code
  const onChangeReferral = (e) => {
    let value = e.target.value;
    setErrorForm({
      ...errorForm,
      referral_code: false,
    });
    return setFormLogin({ ...formLogin, referral_code: value });
  };

  //---------------------------------------------------------------------------------------------------//

  const validateFormLogin = () => {
    let checkError = false;
    let localErrorForm = { ...errorForm };

    if (!formLogin.firstname) {
      localErrorForm.firstname = {
        isError: true,
        message: "กรุณากรอกชื่อ",
      };
      checkError = true;
    }

    if (!formLogin.lastname) {
      localErrorForm.lastname = {
        isError: true,
        message: "กรุณากรอกนามสกุล",
      };
      checkError = true;
    }

    if (!formLogin.birthday) {
      localErrorForm.birthday = {
        isError: true,
        message: "กรุณาระบุวันเกิด",
      };
      checkError = true;
    }

    if (!formLogin.age) {
      localErrorForm.age = {
        isError: true,
        message: "กรุณากรอกอายุ",
      };
      checkError = true;
    }

    if (!formLogin.sex) {
      localErrorForm.sex = {
        isError: true,
        message: "กรุณาระบุเพศ",
      };
      checkError = true;
    }

    if (!formLogin.life_status) {
      localErrorForm.life_status = {
        isError: true,
        message: "กรุณาระบุสถานะ",
      };
      checkError = true;
    }

    if (!formLogin.healing_rights) {
      localErrorForm.healing_rights = {
        isError: true,
        message: "กรุณาระบุสิทธิในการเบิกค่ารักษาพยาบาล",
      };
      checkError = true;
    }

    if (!formLogin.referral_code) {
      localErrorForm.referral_code = {
        isError: true,
        message: "กรุณาระบุ Referral code",
      };
      checkError = true;
    }

    const checkPhoneNumber = validatePhoneNumber(formLogin.phone_number);
    if (!formLogin.phone_number || !checkPhoneNumber) {
      localErrorForm.phone_number = {
        isError: true,
        message: "กรุณากรอกเบอร์โทร",
      };
      checkError = true;
    }

    setErrorForm(localErrorForm);
    return checkError;
  };

  //---------------------------------------------------------------------------------------------------//

  // Submit FormLogin
  const onSubmitFormLogin = async () => {
    const isError = validateFormLogin();
    if (isError) {
      return;
    }
    if (isErrorYear) {
      return;
    }

    try {
      setLoading(true);
      const dateFormated = DateTime.fromJSDate(
        new Date(formLogin.birthday)
      ).toFormat("yyyy-MM-dd");

      let payload = {
        ...formLogin,
        birthday: dateFormated,
      };

      if (payload?._id) {
        delete payload?._id;
      }

      const paramFilter = {
        filter: {
          email: currentEmailLogin,
        },
      };

      const { data: dataProfile } = await Api.get("/profiles", {
        params: paramFilter,
      });

      if (dataProfile?.profiles?.length > 0) {
        const profileByEmail = dataProfile?.profiles[0];
        const id = profileByEmail._id;

        // update profile table by id
        await Api.put(`profiles/${id}`, payload);

        // setOpenAlert(true);
        navigate(`/familytree?email=${currentEmailLogin}`);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (userData) {
      setFormLogin({
        ...userData,
        birthday: new Date(userData.birthday),
      });
    }
  }, [userData]);


  return (
    <React.Fragment>
      <div
        className=" font-IBMPlexSansThai flex flex-col relative min-h-screen mx-auto max-w-3xl"
      >
        <div className=" relative pt-[105px] px-[32px]">
          <img
            src="/images/circle_login1.svg"
            className=" absolute top-0 right-0"
            alt=""
          />

          <div>
            <img
              src="/images/logo_assure.png"
              className=" w-[93px] h-[37px]"
              alt=""
            />
            <div className=" text-maincyan font-bold text-5xl leading-relaxed">
              ลงทะเบียน
            </div>
          </div>
          <div className=" flex flex-col gap-4">
            {/*---------- ชื่อ ----------*/}
            <div>
              <label className={STYLE_TEXT_BLUE}>ชื่อ</label>
              <InputMain
                placeholder="ระบุ"
                className={errorForm.firstname.isError && "border-red-500"}
                value={formLogin.firstname}
                onChange={onChangeFirstname}
              />
              {errorForm.firstname.isError && (
                <ErrorMain errortext={errorForm.firstname.message} />
              )}
            </div>

            {/*---------- นามสกุล ----------*/}
            <div>
              <label className={STYLE_TEXT_BLUE}>นามสกุล</label>
              <InputMain
                placeholder="ระบุ"
                className={errorForm.lastname.isError && "border-red-500"}
                value={formLogin.lastname}
                onChange={onChangeLastname}
              />
              {errorForm.lastname.isError && (
                <ErrorMain errortext={errorForm.lastname.message} />
              )}
            </div>

            {/*---------- วันเกิด ----------*/}
            <div className="">
              <div className={STYLE_TEXT_BLUE}>วันเกิด</div>
              <DatePicker
                selected={formLogin.birthday}
                locale={th}
                dateFormat="dd/MM/yyyy"
                renderCustomHeader={CustomInputBirthdayHearder}
                onKeyDown={(e) => {
                  e.preventDefault();
                }}
                wrapperClassName="w-full"
                customInput={<CustomInputBirthday />}
                // showTimeSelect
                onChange={(date) => onChangeBirthday(date)}
              />
              {errorForm.birthday.isError && (
                <ErrorMain errortext={errorForm.birthday.message} />
              )}
              {isErrorYear && (
                <ErrorMain errortext={"กรุณาระบุวันเกิดให้ถูกต้อง"} />
              )}
            </div>

            {/*---------- อายุ ----------*/}
            <div>
              <label className={STYLE_TEXT_BLUE}>อายุ</label>
              <InputMain
                type="number"
                placeholder="ระบุ"
                disabled={true}
                className=""
                value={formLogin.age}
              />
            </div>

            {/*---------- เพศ ----------*/}
            <div>
              <label className={STYLE_TEXT_BLUE}>เพศ</label>
              <div
                className="grid grid-cols-2 gap-2.5 items-center"
                onChange={onChangeSex}
              >
                <label className="flex items-center gap-2.5 border rounded-[10px] px-[14px] py-[8px]">
                  <input
                    type="radio"
                    name="sex"
                    value="ชาย"
                    checked={formLogin.sex === "ชาย" ? true : false}
                  />{" "}
                  <img src="/images/icon_male.png" className="w-6 h-6" />
                  ชาย
                </label>
                <label className="flex items-center gap-2.5 border rounded-[10px] px-[14px] py-[8px]">
                  <input
                    type="radio"
                    name="sex"
                    value="หญิง"
                    checked={formLogin.sex === "หญิง" ? true : false}
                  />{" "}
                  <img src="/images/icon_female.png" className="w-6 h-6" />
                  หญิง
                </label>
              </div>
              {errorForm.sex.isError && (
                <ErrorMain errortext={errorForm.sex.message} />
              )}
            </div>

            {/*--- สถานะ ---*/}
            <div>
              <label className={STYLE_TEXT_BLUE}>สถานะ</label>
              <div
                className={`border rounded-full ${
                  errorForm.life_status.isError
                    ? "border-red-500"
                    : "border-gray-200"
                } `}
              >
                <DropDownMain
                  placeholder={`กรุณาเลือกสถานะ`}
                  options={LIFE_STATUS_LIST}
                  value={LIFE_STATUS_LIST?.filter((item) => {
                    if (item.label === formLogin.life_status) return item.label;
                  })}
                  onChange={onChangeSelectedStatus}
                />
              </div>
              {errorForm.life_status.isError && (
                <ErrorMain errortext={errorForm.life_status.message} />
              )}
            </div>

            {/*---------- เบอร์โทร ----------*/}
            <div>
              <label className={STYLE_TEXT_BLUE}>เบอร์โทร</label>
              <InputMain
                type="number"
                placeholder="ระบุ"
                className={errorForm.phone_number.isError && "border-red-500"}
                value={formLogin.phone_number}
                onChange={onChangePhoneNumber}
              />
              {errorForm.phone_number.isError && (
                <ErrorMain errortext={errorForm.phone_number.message} />
              )}
            </div>

            {/*---------- เลขประจำตัวผู้เสียภาษีอากร (ถ้ามี) ----------*/}
            <div>
              <label className={STYLE_TEXT_BLUE}>
                เลขประจำตัวผู้เสียภาษีอากร
              </label>
              <InputMain
                type="number"
                placeholder="เช่น 0994000158441"
                value={formLogin.tax_id}
                onChange={onChangeTaxId}
              />
            </div>
             {/*---------- สิทธิในการเบิกค่ารักษาพยาบาล ----------*/}
            <div>
              <label className={STYLE_TEXT_BLUE}>
              สิทธิในการเบิกค่ารักษาพยาบาล
              </label>
              <InputMain
                type="text"
                placeholder="ระบุ"
                value={formLogin?.healing_rights}
                onChange={onChangeHealingRight}
              />
            </div>
            {/*----------------- Referral code ---------------*/}
            <div>
              <label className={STYLE_TEXT_BLUE}>
              Referral code
              </label>
              <InputMain
                type="text"
                placeholder="ระบุ"
                value={formLogin?.referral_code}
                onChange={onChangeReferral}
              />
            </div>
          </div>
        </div>
        <div className="flex justify-center gap-2.5 w-full px-[8.5px] mt-[26px] mb-[38px]">
          <MTButton
            text="ย้อนกลับ"
            color="bg-white"
            border="border border-maincyan"
            colortext="text-maincyan group-hover:text-white"
            hovercolor="hover:bg-maincyan group "
            width="w-full"
            onClick={() => navigate("/login")}
          />
          <MTButton
            text={"ถัดไป"}
            color="bg-maincyan"
            width="w-full"
            onClick={onSubmitFormLogin}
          />
        </div>
      </div>
    </React.Fragment>
  );
};

export default FirstLogin;
